import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'HACHIKO',
    tokenAddress: '0x3ae20ee46822efad0099a40be21a36057914d963',   // token address
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x3ae20ee46822efad0099a40be21a36057914d963',  // token address
    contractAddress: {
      97: '',
      56: '0x34A657340903aB251237Fbb68fd798b1A36D2C2C',  /// masterchef address
    },
    poolCategory: PoolCategory.CORE,
    projectLink: '/',
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
 
]

export default pools
