import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'HACHIKO',
    lpAddresses: {
      97: '',
      56: '0x3ae20ee46822efad0099a40be21a36057914d963',
    },
    tokenSymbol: 'SYRUP',
    tokenAddresses: {
      97: '',
      56: '0x3ae20ee46822efad0099a40be21a36057914d963',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 1,
    lpSymbol: 'HACHIKO-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xE29Ad2C6920eB0f65D8055d91921f67f73dDa7D8',   // lp address token-bnb
    },
    tokenSymbol: 'HACHIKO',
    tokenAddresses: {
      97: '',
      56: '0x3ae20ee46822efad0099a40be21a36057914d963', // token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

]

export default farms
